$theme-colors: (
  'primary': #1e4d2b,
  'secondary': #c8c372,
  'success': #82c503,
  'info': #008fb3,
  'warning': #ffc038,
  'danger': #e56a54,
  'light': #f7f7f7,
  'dark': #59595b,
  'white': #ffffff,
  'transparent': rgba(0, 0, 0, 0),
  'purple': #7e5475,
  'energy_green': #cffc00,
  'highlight': #cee4e5,
);

@import '~bootstrap/scss/bootstrap';

body {
  background-image: url('/../public/background.png');
}

.form-label {
  font-weight: bold;
}

#logsTable td {
  word-wrap: break-word;
}

.accordion-button:not(.collapsed) {
  background-color: map-get($map: $theme-colors, $key: 'light');
  box-shadow: none;
  color: black;
}

.accordion-button.collapsed {
  box-shadow: none;
}

.bg-white__input:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem #bcd5f8;
}

/* Total scrollbar width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Thumb (the part you can move) */
::-webkit-scrollbar-thumb {
  background: map-get($map: $theme-colors, $key: 'secondary');
  border-radius: 4px;
}

// background of scrollbar ::-webkit-scrollbar-track
// Thumb hovered ::-webkit-scrollbar-thumb:hover

.vertical-middle {
  vertical-align: middle !important;
}

.truncateTxt {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.w-90 {
  width: 90%;
}

.list-group-item-light {
  background-color: map-get($map: $theme-colors, $key: 'light');
}

.searchSelect.active {
  background-color: #e0e0e6;
}

.pointer {
  cursor: pointer;
}

.docsButton:hover {
  background-color: darken(#ffffff, 10);
}

.docsButton.active {
  background-color: darken(#ffffff, 10);
  color: map-get($map: $theme-colors, $key: 'primary');
  font-weight: bold;
}

.display-card {
  max-height: 300px;
}

.card-animation {
  transition: all 0.3s ease;
}

.card-animation:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 5px 15px #001e4d2b;
}

.nav-collapse-button {
  position: absolute;
  height: 40px;
  width: 20px;
  border-radius: 0% 100% 100% 0%;
  left: 100%;
  top: 4px;
  background-color: map-get($map: $theme-colors, $key: 'primary');
  color: map-get($map: $theme-colors, $key: 'light');
  padding: 6px 1px;
}

.breadcrumb-link {
  color: map-get($map: $theme-colors, $key: 'light');
  text-decoration: none;
}

.breadcrumb-link:hover {
  border-radius: 4px;
  text-decoration: underline;
  background: lighten(
    $color: map-get($map: $theme-colors, $key: 'primary'),
    $amount: 5
  );
}

.inspect-toggle .nav-link {
  background-color: map-get($map: $theme-colors, $key: 'white');
  color: gray;
  border-top: 1px solid map-get($map: $theme-colors, $key: 'light');
  border-right: 1px solid map-get($map: $theme-colors, $key: 'light');
  border-left: 1px solid map-get($map: $theme-colors, $key: 'light');
  border-bottom: 0px;
  font-weight: 700;
  font-size: 18px;
}

.inspect-toggle .nav-link:hover {
  color: map-get($map: $theme-colors, $key: 'primary');
  border-top: 1px solid map-get($map: $theme-colors, $key: 'primary');
  border-right: 1px solid map-get($map: $theme-colors, $key: 'primary');
  border-left: 1px solid map-get($map: $theme-colors, $key: 'primary');
}

.inspect-toggle .nav-link.active {
  background-color: map-get($map: $theme-colors, $key: 'primary');
  color: map-get($map: $theme-colors, $key: 'light');
  border-top: 1px solid map-get($map: $theme-colors, $key: 'primary');
  border-right: 1px solid map-get($map: $theme-colors, $key: 'primary');
  border-left: 1px solid map-get($map: $theme-colors, $key: 'primary');
  border-bottom: 0px;
}
